import Vue from 'vue'
import axios from 'axios'

import APIException from './exceptions'

const SET_LOADING = 'SET_LOADING'
const SET_ITEMS = 'SET_ITEMS'

const CancelToken = axios.CancelToken
let groupsListCancel

const moduleGroup = {
  namespaced: true,
  state: {
    items: [],
    total: 0,
    loading: false
  },
  mutations: {
    [SET_LOADING] (state, val) {
      state.loading = val
    },
    [SET_ITEMS] (state, val) {
      state.items = val.objects
      state.total = val.meta.total
    }
  },
  actions: {
    async listGroups ({ commit }) {
      commit(SET_LOADING, true)
      try {
        if (groupsListCancel) { groupsListCancel() }
        const response = await Vue.axios.get('/api_v2/groups', {
          params: {'all': true},
          cancelToken: new CancelToken(function executor(c) {
            groupsListCancel = c
          })
        })
        if (response.status >= 300) { throw response }
        commit(SET_ITEMS, response.data)
        return response
      } catch (err) {
        throw new APIException(err)
      } finally {
        commit(SET_LOADING, false)
      }
    }
  },
  getters: {
    loading: state => state.loading,
    items: state => state.items
  }
}

export default moduleGroup
