<template>
  <header
    class="header"
    :class="{'scrolled': scrolled}"
  >
    <div class="header-bar">
      <span class="header-left">
        <!-- LOGO -->
        <router-link :to="{name: 'home'}">
          <Logo
            data-cy="logo"
            :color="false"
            height="36"
            margin="0 15"
          />
        </router-link>
      </span>

      <span class="header-middle">
        <router-link
          v-if="hasCampaignAccess"
          :to="{name: 'home'}"
          exact-active-class="header-menu-link-active"
          class="header-menu-link"
        >
          {{ $t('global.dashboard') }}
        </router-link>
        <router-link
          v-if="hasCampaignAccess"
          :to="{name: 'email-list'}"
          active-class="header-menu-link-active"
          class="header-menu-link"
        >
          {{ $t('labels.emails') }}
        </router-link>
        <router-link
          v-if="hasCampaignAccess"
          :to="{name: 'sms-list'}"
          active-class="header-menu-link-active"
          class="header-menu-link"
        >
          {{ $t('labels.sms') }}
        </router-link>
        <router-link
          v-if="hasCampaignAccess"
          :to="{name: 'push-list'}"
          active-class="header-menu-link-active"
          class="header-menu-link"
        >
          {{ $t('labels.push') }}
        </router-link>
        <router-link
          v-if="hasModuleJourneys"
          class="header-menu-link"
          active-class="header-menu-link-active"
          :to="{name: 'journeys-list'}"
        >
          {{ $t("labels.journeys") }}
        </router-link>
        <router-link
          v-if="hasCampaignAccess"
          :to="{name: 'landing-list'}"
          active-class="header-menu-link-active"
          class="header-menu-link"
        >
          {{ $t('labels.landing_page') }}
        </router-link>
        <router-link
          v-if="hasModuleQR"
          :to="{name: 'qr-list'}"
          active-class="header-menu-link-active"
          class="header-menu-link"
        >
          {{ $t('labels.qr_codes') }}
        </router-link>
        <router-link
          :to="{name: 'reports-list'}"
          active-class="header-menu-link-active"
          class="header-menu-link"
        >
          {{ $t('labels.reports') }}
        </router-link>
      </span>

      <span class="header-right">
        <!-- SNAPCALL -->
        <SnapcallButton
          v-if="snapcallId"
          class="ml-1 mr-1"
          :is-active="showSnapcall"
          @update:is-active="launchSnapcall"
        />
        <!-- SETTINGS -->
        <router-link
          v-if="hasAdminPerm"
          :to="{ name: 'admin-reports' }"
          active-class="header-menu-link-active"
          class="header-menu-link icon"
        >
          <i class="material-icons">settings</i>
        </router-link>
      </span>
      <!-- GATE SWITCH -->
      <NmpSwitch
        :clients="clients"
        :apps="apps"
        :current-switch-client="currentSwitchClient"
        :initial-client="initialClient"
        @update:currentSwitchClient="updateSwitchClient"
      />

      <!-- USER MENU -->
      <el-dropdown
        trigger="hover"
        @command="handleCommand"
      >
        <el-button
          type="text"
          class="mr-2 ml-1 header-account header-button el-dropdown-link"
          :class="{ 'mono-client-mode': clientLogo }"
        >
          <img
            v-if="clientLogo"
            class="client-logo mr-2"
            alt="Client logo"
            :src="clientLogo"
          >
          <img
            v-if="user.avatar_url"
            class="header-round-img"
            alt="User avatar"
            :src="user.avatar_url"
          >
          <img
            v-else-if="!user.avatar_url && initials.length > 0"
            v-holder="`holder.js/30x30?text=${initials}`"
            alt="User avatar"
            class="header-round-img"
          >
        </el-button>
        <el-dropdown-menu
          slot="dropdown"
          class="menu-account"
        >
          <div class="menu-account--detail-container">
            <div class="menu-account--avatar-container">
              <img
                v-if="user.avatar_url"
                :src="user.avatar_url"
                style="border-radius: 50%;"
                alt="User avatar"
                width="48"
                height="48"
              >
              <img
                v-else-if="!user.avatar_url && initials.length > 0"
                v-holder="`holder.js/48x48?text=${initials}`"
                class="menu-account--avatar"
                alt="User avatar"
              >
            </div>
            <div class="mb-1">
              {{ `${user.first_name} ${user.last_name}` }}
            </div>
            <div class="mb-1 menu-account--email">
              {{ user.email }}
            </div>
          </div>
          <div>
            <el-dropdown-item command="profile">
              <i class="material-icons">perm_identity</i>
              {{ $t('BUTTON.PROFILE') }}
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              <i class="material-icons">arrow_forward</i>
              {{ $t('BUTTON.SIGN_OUT') }}
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- </span> -->
    </div>
  </header>
</template>

<script>
import Logo from './Logo'
import { NmpSwitch, SnapcallButton } from '@team-crm-automation/nmp-components-vue2'

export default {
  name: 'AppHeader',
  components: {
    Logo,
    NmpSwitch,
    SnapcallButton
  },
  props: {
    scrolled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentSwitchClient: null,
    }
  },
  computed: {
    user () {
      return this.$store.getters['session/user'] || {}
    },
    client () {
      return this.$store.getters['clients/client'] || {}
    },
    clients () {
      return this.$store.getters['clients/clients'] || []
    },
    apps () {
      return this.$store.getters['apps/apps'].apps || []
    },
    initialClient() {
      return this.client
    },
    clientLogo () {
      const client = this.client
      if (!client.configs || !client.configs.display_logo_in_app) {
        return null
      }
      return client.configs.interface_logo_url ? client.configs.interface_logo_url : client.logo_url
    },
    initials () {
      const firstname = this.user.first_name || ''
      const lastname = this.user.last_name || ''
      if (firstname.length === 0 && lastname.length === 0) {
        return ''
      }
      return this.$options.filters.initials(`${firstname} ${lastname}`)
    },
    hasAdminPerm () {
      return this.$store.getters['session/hasPermission']('dashboardly:user:reports')
    },
    hasCampaignAccess () {
      return (
        this.$store.getters['session/hasPermission']('dashboardly:role:campaigns:r')
        && this.$store.getters['session/hasCRMDataBase']
      )
    },
    hasModuleJourneys () {
      return this.$store.getters['session/hasModuleJourneys']
    },
    hasModuleQR () {
      return this.$store.getters['session/hasModuleQR']
    },
    showSnapcall() {
      return this.$store.getters['snapcall/showSnapcall']
    },
    snapcallId() {
      return this.$store.getters['snapcall/snapcallId']
    }
  },
  watch: {
    initialClient: async function(newVal) {
      if (newVal) {
        await this.$store.dispatch('apps/getApps', this.initialClient['id'])
        this.currentSwitchClient = this.initialClient
      }
    }
  },
  methods: {
    handleCommand (command) {
      switch (command) {
        case 'logout':
          this.$store.dispatch('session/logout')
          break
        case 'profile':
          window.open('/profile', '_blank')
          break
      }
    },
    launchSnapcall() {
      if (this.showSnapcall) {
        this.$store.dispatch('snapcall/endSnapcall')
      } else {
        this.$store.dispatch('snapcall/startSnapcall')
      }
    },
    async updateSwitchClient (client) {
      await this.$store.dispatch('apps/getApps', client ? client['id'] : null)
      this.currentSwitchClient = client
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.header-menu-link {
  margin: 0 3px;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 32px;
  display: inline-block;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.2s;
  text-decoration: none;

  &.icon {
    padding: 5px 10px;
    margin: 0 5px;
  }
  &.header-menu-link-active{
    color: white;
  }
  &:hover {
    color: white;
  }
  i.material-icons {
    font-size: 20px;
    display: block;
  }
}

.header {
  z-index: 500;
  transition: box-shadow .4s ease-out;

  &.scrolled {
    box-shadow: 0 2px 8px 0px rgb(0 0 0 / 30%);
  }

  .header-left > a {
    display: flex;
  }

  .el-button.el-button--text {
    padding: 7px;
  }
  .header-account.el-button.el-button--text {
    padding: 3px;
    margin: 0 17px 0 15px;

    &.mono-client-mode {
      padding: 2px 10px 2px 5px;
      background: white;
      margin: 0 4px 0 15px !important;

      .client-logo {
        height: 38px;
        margin-left: -2px;
        margin-right: 12px;
      }
    }
  }

  .el-badge__content.is-fixed.is-dot {
    top: 2px;
    right: 6px;
    border-color: #1fb28c;
  }

  .el-button + .el-button {
    margin-left: 0;
  }
}
.el-dropdown-menu.menu-account {
  padding: 0;
}

.menu-account {
  width: 240px;
  overflow-y: hidden;

  .el-dropdown-menu__item {
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top: 1px solid #eef1f6;
  }

  i {
    vertical-align: text-bottom;
    font-size: 18px;
    width: 30px;
    text-align: center;
  }
}

.menu-account--detail-container {
  padding: 20px 10px 20px;
  text-align: center;
  font-size: 14px;
}

.menu-account--avatar-container {
  width: 48px;
  height: 48px;
  margin: 0 auto 10px;
}

.menu-account--avatar {
  width: 48px;
  height: 48px;
  box-shadow: none;
  border-radius: 50%;
}

.menu-account--email {
  color: #aaa;
}

.header-right {
  .snapcall-button {
    margin: auto 6px;
    min-width: 36px;
    ::v-deep i {
      margin-right: 0;
    }
  }
  .switch-container {
    margin-right: 0;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .header .header-bar {
    .header-left {
      width: auto;
      flex-grow: 1;
      text-align: center;
    }

    .header-middle {
      display: none;
    }

    .header-right {
      width: 70px;

      .gate-switch {
        display: none;
      }
    }
  }
}
</style>
