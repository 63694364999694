<template>
  <div :class="`block-param bp-evolution bp-${channel}`">
    <div class="row">
      <h2>{{ $t(`detail.evolution.${channel}`) }}</h2>
      <el-select
        v-model="currentPeriod"
        size="medium"
        style="width: 220px"
        @change="updateStep()"
      >
        <el-option
          v-for="p in periods"
          :key="p"
          :label="p + `${$t('detail.following_sending')}`"
          :value="p"
        />
      </el-select>
    </div>
    <ChartLine
      :chart-data="bothCharts"
      :options="chartOptions"
    />
    <div class="legende-container">
      <GraphAutoLegend
        :labels="labels"
        position="bottom"
        :tooltips="true"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import config from '@/config'
import { isoDateString } from '@/helpers/isoDate'
import { ChartLine } from '@/components/Common/Chart'
import { GraphAutoLegend } from '@/components/Common/Graph'

export default {
  name: 'BlockEvolution',
  components: { ChartLine, GraphAutoLegend },
  props: {
    reactions: {
      type: Array,
      default: () => []
    },
    sendingdate: {
      type: [String, null],
      default: null
    },
    channel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      periods: [24, 48, 72],
      currentPeriod: 24,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              callback: function(value, index) {
                var isShown = true
                switch (this.currentPeriod) {
                  case 24: isShown = true
                    break
                  case 48: isShown = index % 2 == 0
                    break
                  case 72: isShown = index % 3 == 0
                    break
                  default: isShown = true
                }

                var date = moment.tz(value, 'L LT', this.userTimezone)
                return isShown ? date.format('HH:mm') : '';
              },
            },
          }],
        },
      }
    }
  },
  computed: {
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    labels () {
      return this.reactions.map((reaction) => ({
        'label': this.$t(`labels.${reaction.label}`),
        'tooltip': this.$t(`labels.${reaction.label}_tip`)
      }))
    },
    campaignId () {
      const parsed = parseInt(this.$route.params.campaignId)
      if (isNaN(parsed)) {
        return this.$route.params.campaignId
      }
      return parsed
    },
    campaign () {
      return this.$store.getters[this.channel +'Campaign/campaign'] || {}
    },
    bothCharts () {
      if (this.reactions.length === 0) {
        return { labels: [], datasets: [] }
      }
      return {
        labels: this.labelizer(this.reactions),
        datasets: this.reactions.map((item, index) => this.customizer(item, index))
      }
    },
    rangeDate () {
      const { start, end } = this.$store.getters['app/rangeDate']
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(start, tz),
        end: isoDateString(end, tz, true),
      }
    }
  },
  methods: {
    updateStep () {
      const params = { period: this.currentPeriod }
      const id = this.campaignId
      const upperCaseChannel = this.channel.toUpperCase()
      if (this.campaign.campaign_type !== config[upperCaseChannel + '_CHANNEL_TAGS'].one_shot) {
        params.start_date = this.rangeDate.start
        params.end_date = this.rangeDate.end
      }
      this.$store.dispatch(this.channel + 'Campaign/getReactions', { id, params })
    },
    filterDates (_data) {
      let _start, _end

      switch (this.currentPeriod) {
        case 24: _end = moment(this.sendingdate).endOf('day')
          break
        case 48: _end = moment(this.sendingdate).add(1, 'days').endOf('day')
          break
        case 72: _end = moment(this.sendingdate).add(2, 'days').endOf('day')
          break
        default: _end = moment(this.sendingdate).endOf('day')
      }
      _start = moment(this.sendingdate).startOf('day')
      return _data.filter(item => moment(item.x).isBetween(_start, _end, null, '[]'))
    },
    labelizer (data) {
      return this.filterDates(data[0].data).map(item =>
        this.$options.filters.formatDate(item.x, this.$i18n.locale, this.userTimezone, 'L LT')
      )
    },
    customizer (curveData, index) {
      const _label = `${this.$t('global.' + curveData.label)}`
      const _data = this.filterDates(curveData.data).map(item => item.y)
      const _color = this.$options.filters.autocolorize(index)
      return {
        label: _label,
        data: _data,
        fill: false,
        borderColor: _color,
        backgroundColor: _color,
        pointBackgroundColor: _color,
        tension: 0,
      }
    }
  }
}
</script>

<style lang="scss">
.bp-evolution {
  .legende-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .legende {
      ul {
        display: flex;
        li {
          margin: 1rem;
        }
      }
    }
  }
}
</style>
