<template>
  <section class="detail-page sms">
    <HeaderSMS
      :current-split="currentSplit"
      :is-journey-message="isJourneyMessage"
      :download-loading="downloadLoading"
      :header-splits="headerSplits"
      :campaign="campaign"
      :campaign-meta="campaignMeta"
      @split-updated="splitUpdated"
      @select-table-split="selectTableSplit"
      @generate-export="generateExport"
      @updated-range-date="loadCampaign"
      @change-campaign="handleChangeCampaign"
    />
    <el-alert
      v-show="!isOneShot && campaignMeta.hasOwnProperty('sending_date') && !campaignMeta.sending_date"
      class="detail-alert"
      type="info"
      :center="true"
      :title="$t('detail.no_sending_date')"
      :closable="false"
      show-icon
    />
    <el-tabs
      :value="selectedTab"
      :stretch="true"
      @tab-click="handleClickTabs"
    >
      <el-tab-pane name="sms-detail-overview">
        <span slot="label">
          <i class="material-icons md-18">visibility</i>
          {{ $t(`detail.overview`) }}
        </span>
      </el-tab-pane>
      <el-tab-pane name="sms-detail-conversions">
        <span slot="label">
          <i class="material-icons md-18">monetization_on</i>
          {{ $t('detail.conversions') }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <router-view class="detail-tabs-view" />
  </section>
</template>

<script>
import config from '@/config'
import { isoDateString } from '@/helpers/isoDate'
import { HeaderSMS } from '@/components/Parts/Header'
import {
  cloneDeep as _cloneDeep,
} from 'lodash-es'

export default {
  name: 'ViewSMSDetail',
  components: {
    HeaderSMS
  },
  props: {
    isJourneyMessage: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    campaignId () {
      const parsed = parseInt(this.$route.params.campaignId)
      if (isNaN(parsed)) {
        return this.$route.params.campaignId
      }
      return parsed
    },
    campaign () {
      return this.$store.getters['smsCampaign/campaign'] || {}
    },
    selectedTab () {
      return this.$route.name.replace('messages-', '')
    },
    campaignMeta () {
      return this.$store.getters['smsCampaign/headerMeta'] || {}
    },
    loading () {
      return this.$store.getters['smsCampaign/loading']
    },
    isOneShot () {
      return this.campaign.campaign_type === config.SMS_CHANNEL_TAGS.one_shot
    },
    headerSplits () {
      return this.$store.getters['smsCampaign/headerSplits']
    },
    currentSplit () {
      return this.$store.getters['smsCampaign/currentSplit']
    },
    rangeDateRaw () {
      return this.$store.getters['app/rangeDate']
    },
    rangeDate () {
      const { start, end } = this.$store.getters['app/rangeDate']
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(start, tz),
        end: isoDateString(end, tz, true),
      }
    },
    downloadLoading () {
      return this.$store.getters['smsCampaign/downloadLoading']
    },
    currentConversionFilter() {
      return this.$store.getters['conversions/currentFilter']
    }
  },
  watch: {
    currentConversionFilter() {
      this.loadConversions()
    }
  },
  async created () {
    this.$store.dispatch('smsCampaign/resetState', this.campaignId)

    // Retrieve campaign and make it sync to ensure campaign exist before loading next data
    if (this.campaign.campaign_id !== this.campaignId) {
      const response = await this.$store.dispatch('smsCampaign/getCampaign', this.campaignId)
      if (response.status != 200) {
        if (response.status >= 500) {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        } else {
          this.$message({
            showClose: true,
            center: true,
            duration: 0,
            message: this.$t('global.alert_no_campaign'),
          })
        }
        this.$router.push({ name: 'home' })
        return
      }
    }

    // Inject date if filter has been activated on this campaign from url
    for (let key in this.$route.query) {
      switch (key) {
      case 'start_date':
        this.$store.dispatch('app/setStartDate', this.$route.query[key])
        break
      case 'end_date':
        this.$store.dispatch('app/setEndDate', this.$route.query[key])
        break
      }
    }
    this.loadCampaign()
  },
  methods: {
    loadCampaign () {
      const id = this.campaignId
      const params = { }
      if (!this.isOneShot) {
        params.start_date = this.rangeDate.start
        params.end_date = this.rangeDate.end
      }
      this.$store.dispatch('smsCampaign/getOverview', { id, params })
      this.$store.dispatch('smsCampaign/getHeaderSplits', { id, params })
      this.$store.dispatch('smsCampaign/getHeaderMeta', { id, params })
      this.$store.dispatch('smsCampaign/getCountries', { id, params })

      // Only refresh conversions if the current page is the conversion
      if (this.$route.name.include('sms-detail-conversions')) {
        this.loadConversions()
        if (this.isOneShot) {
          this.loadDailyConversions()
        }
      }
      this.urlParams()
    },
    loadConversions () {
      const params = {
        campaign_id: this.campaignId,
        channel_id: config.CHANNELS.SMS,
        start_date: this.rangeDate.start,
        end_date: this.rangeDate.end,
      }
      if (this.currentSplit.length && this.currentSplit[0].type) {
        params['split'] = this.currentSplit.map(item => item.id).join('|')
        params['split_type'] = this.currentSplit[0].type
        if ('extra_data_id' in this.currentSplit[0]) {
          params['extra_data_id'] = this.currentSplit[0].extra_data_id
        }
      }

      if (this.currentConversionFilter !== 'all') {
        const type = this.currentConversionFilter.toUpperCase()
        params['conversion_type_id'] = config.CONVERSION_TYPES[type]
      }

      if (this.isOneShot) {
        delete params.start_date
        delete params.end_date
      }
      
      this.$store.dispatch('conversions/getConversions', params)
    },
    loadDailyConversions () {
      const params = {
        campaign_id: this.campaignId,
        channel_id: config.CHANNELS.SMS,
        start_date: this.rangeDate.start,
        end_date: this.rangeDate.end,
        sending_date: this.campaign.start_date
      }
      if (this.currentSplit.length && this.currentSplit[0].type) {
        params['split'] = this.currentSplit.map(item => item.id).join('|')
        params['split_type'] = this.currentSplit[0].type
        if ('extra_data_id' in this.currentSplit[0]) {
          params['extra_data_id'] = this.currentSplit[0].extra_data_id
        }
      }
      this.$store.dispatch('conversions/getDailyConversions', params)
    },
    urlParams () {
      let queryParams = _cloneDeep(this.$route.query) || { }
      if (!this.isOneShot) {
          queryParams['start_date'] = this.rangeDateRaw.start
          queryParams['end_date'] = this.rangeDateRaw.end
      }
      this.$router.replace({ query: queryParams }).catch(() => {})
    },
    generateExport () {
      const params = { }
      if (!this.isOneShot) {
        params.start_date = this.rangeDate.start
        params.end_date = this.rangeDate.end
      }
      this.$store.dispatch('smsCampaign/exportCampaign', {
        id: this.campaignId,
        params: params
      })
    },
    selectTableSplit (split) {
      this.$store.dispatch('smsCampaign/selectSplit', split)
      this.$router.push({
        name: 'sms-detail-table',
        params: { campaignId: this.campaignId }
      })
    },
    splitUpdated (split) {
      let selectedSplit = split ? [split] : []
      this.$store.dispatch('smsCampaign/selectSplit', selectedSplit)
      this.loadCampaign()
    },
    handleClickTabs (tab) {
      if (this.$route.name.includes(tab.name)) {
        return
      } else if (tab.name === 'sms-detail-conversions') {
        this.loadConversions()
        if (this.isOneShot) {
          this.loadDailyConversions()
        }
      }
      let query = { }
      if (!this.isOneShot) {
        query = {
          start_date: this.$store.getters['app/rangeDate'].start,
          end_date: this.$store.getters['app/rangeDate'].end
        }
      }
      this.$router.push({
        name: this.isJourneyMessage ? `messages-${tab.name}` : tab.name,
        params: { campaignId: this.campaignId },
        query: query
      })
    },
    handleChangeCampaign(campaignId) {
      this.$router.push({ params: { campaignId: campaignId }, query: this.$router.currentRoute.query })
      this.loadCampaign()
    }
  }
}
</script>

<style lang="scss">
.detail-page.sms {
  .clickmap .map {
    margin-top: 59px;
  }
}
.el-tabs__item {
  font-size: 16px;
  i {
    line-height: 2.3;
    height: 16px;
    vertical-align: top;
    display: inline-block;
  }
}
</style>
