<template>
  <section class="overview-push-section">
    <div class="col-left">
      <template v-for="(kpisValue, kpisKey) in overview">
        <component
          :is="components_map[kpisKey].name"
          v-if="isKpi(kpisKey)"
          :key="kpisKey"
          :option="components_map[kpisKey].option"
          :kpiskey="kpisKey"
          :kpisvalue="kpisValue"
          :kpis="kpis"
          :overview="overviewData"
          :channel="'push'"
        />
      </template>
      <BlockEvolution
        v-if="showEvolution"
        :reactions="reactions"
        :sendingdate="campaign.start_date"
        :channel="'push'"
      />
    </div>

    <!-- mobile screen -->
    <div
      v-if="pushContent"
      class="col-right"
    >
      <div class="screen-choice">
        <i
          class="material-icons switch-button"
          @click="changeScreen"
        >
          keyboard_arrow_left
        </i>
        <div> {{ $t(`detail.${screenType}`) }} </div>
        <i
          class="material-icons switch-button"
          @click="changeScreen"
        >
          keyboard_arrow_right
        </i>
      </div>

      <div
        v-if="campaignOs"
        class="preview mobile-emulator"
        :class="`mobile-emulator-${campaignOs}-${screenType}`"
      >
        <div class="screen">
          <div
            v-if="screenType === 'locked'"
            :class="`header-preview-${campaignOs}`"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="campaignOs === 'ios'"
              class="lock"
            >
              <img
                src="../../../assets/svg/push-notification/preview/icons/lock.svg"
                alt="Lock image"
              >
            </div>
            <div
              class="time"
              v-html="currentDate.time"
            />
            <div
              class="date"
              v-html="currentDate.date"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <div
            v-if="campaignOs === 'ios' && screenType === 'extended'"
            style="padding-left: 85%"
          >
            <i class="material-icons escape-ios">clear</i>
          </div>
          <div :class="bodyPreviewClass">
            <!-- eslint-disable vue/no-v-html -->
            <div class="body-preview-app-name">
              <i
                v-if="campaignOs === 'android'"
                class="material-icons body-preview-app-name-item"
              > email </i>
              <i
                v-else-if="screenType === 'extended'"
                class="material-icons body-preview-app-name-item"
                style="color: #EDEDED"
              > account_balance_wallet </i>
              <i
                v-else
                class="material-icons body-preview-app-name-item"
                style="color: white"
              > account_balance_wallet </i>
              <div
                class="body-preview-app-name-item"
                v-html="overviewData.app_name || $t('detail.test_app')"
              />
              <div
                v-if="campaignOs === 'android' || screenType === 'locked'"
                class="body-preview-app-name-item body-preview-app-name-now"
                v-html="$t('detail.now')"
              />
              <div
                v-else-if="campaignOs === 'ios'"
                class="body-preview-app-name-item body-preview-app-name-extended"
              >
                <i class="material-icons"> lens </i>
                <i class="material-icons"> lens </i>
                <i class="material-icons"> lens </i>
              </div>
              <i
                v-if="campaignOs === 'android' && screenType === 'locked'"
                class="material-icons body-preview-app-name-item"
              >
                keyboard_arrow_down
              </i>
              <i
                v-else-if="campaignOs === 'android' && screenType === 'extended'"
                class="material-icons body-preview-app-name-item"
              >
                keyboard_arrow_up
              </i>
            </div>
            <div
              class="title"
              v-html="pushContent.title"
            />
            <div
              class="msg"
              v-html="pushContent.message"
            />
            <!-- eslint-enable vue/no-v-html -->
            <div
              v-if="campaignOs === 'android' && screenType === 'extended'"
              class="footer"
            >
              <div class="footer-item">
                {{ $t('detail.notification_settings') }}
              </div>
              <div class="footer-item">
                {{ $t('detail.notification_delete') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from '@/config'
import {
  BlockGeneric,
  BlockEvolution,
  BlockInPush,
  BlockLanding
} from '@/components/Common/Block'

export default {
  name: 'ViewPushDetailOverview',
  components: {BlockGeneric, BlockInPush, BlockEvolution, BlockLanding},
  data () {
    return {
      components_map: {
        delivery: { name: 'BlockGeneric', option: null },
        reactions: { name: 'BlockInPush', option: null },
        landing_pages: { name: 'BlockLanding', option: null },
      },
      kpis: {
        sortOrder: {
          delivery: [
            'pn_sent',
            'delivery_rate',
            'delivered',
            'undelivered',
            'soft_bounces',
            'hard_bounces',
            'in_progress'
          ],
          reactions: [
            'open_rate',
            'unique_opens'
          ],
          landing_pages: [
            'visit_rate',
            'click_rate',
            'total_visits',
            'unique_visits',
            'total_clicks',
            'unique_clicks',
            'total_submissions'
          ],
        },
        important: [
          'pn_sent',
          'delivery_rate',
          'open_rate',
          'unique_opens',
          'visit_rate',
          'click_rate'
        ],
        name: {
          pn_sent: 'send',
          delivery_rate: 'delivery',
          open_rate: 'push_open',
          unique_opens: 'push_unique_opens',
          visit_rate: 'visit',
          click_rate: 'click'
        }
      },
      notKpi: [
        'payload'
      ],
      preview: 'mobile',
      screenType: 'locked'
    }
  },
  computed: {
    bodyPreviewClass() {
      let styling = {extended: this.screenType === 'extended'}
      styling['body-preview-' + this.campaignOs] = true
      return styling
    },
    currentDate () {
      const lang = this.$store.getters['session/user'].language || 'en'
      const date = new Date()
      const option = {weekday: 'long', month: 'long', day: 'numeric'}
      if (lang === 'fr') {
        return {
          'date': date.toLocaleDateString("fr-FR", option),
          'time': date.getHours() + ':' + date.getMinutes()
        }
      }
      return {
          'date': date.toLocaleDateString("en-US", option),
          'time': date.getHours() + ':' + date.getMinutes()
        }
    },
    campaignOs () {
      if(this.campaign.os_name === null) {
        // campaigns without os in DB, return android theme by default
        return 'android'
      }
      return this.campaign.os_name === undefined ? null : this.campaign.os_name.toLowerCase()
    },
    campaign () {
      return this.$store.getters['pushCampaign/campaign'] || {}
    },
    overviewData () {
      return this.$store.getters['pushCampaign/overview'] || {}
    },
    reactions () {
      return this.$store.getters['pushCampaign/reactions']
    },
    showEvolution () {
      return this.campaign && this.campaign.campaign_type === config.PUSH_CHANNEL_TAGS.one_shot
    },
    pushContent () {
      if (this.overview.payload && Object.keys(this.overview.payload).length > 0 && this.overview.payload.notifications && this.overview.payload.notifications.length > 0) {
        const notification = this.overview.payload.notifications[0]

        if (this.campaignOs === 'ios') {
          return  { message : notification.alert.body, title : notification.alert.title }
        } else {
          return { message : notification.data._numberly.android.message, title : notification.data._numberly.android.title }
        }
      }
      return { message : "", title : "" }
    },
    overview () {
      let orderedOverview = {}
      for (const key in this.overviewData) {
        if (key == 'app_name') {
          continue
        } else if (!this.kpis.sortOrder[key]) {
          orderedOverview[key] = this.overviewData[key]
          continue
        }
        if (key === 'landing_pages') {
          orderedOverview.landing_pages = []
          const lps = this.overviewData.landing_pages

          for (let i in lps) {
            orderedOverview.landing_pages[i] = {
              id: lps[i].id,
              lp_name: lps[i].lp_name,
              data: this.kpis.sortOrder.landing_pages.map(kpiKey => {
                  return {
                    key: kpiKey,
                    name: this.kpis.name[kpiKey] || null,
                    value: lps[i][kpiKey]
                  }
                })
            }
          }
        } else {
          orderedOverview[key] = this.kpis.sortOrder[key]
            .map(kpiKey => {
              return {
                key: kpiKey,
                name: this.kpis.name[kpiKey] || null,
                value: this.overviewData[key][kpiKey]
              }
            })
        }
      }
      return orderedOverview
    }
  },
  methods: {
    changeScreen () {
      this.screenType = this.screenType === 'locked' ? 'extended' : 'locked'
    },
    /**
     * Check if they key is a KPI
     * @param {String} key (kpi key)
     * @return {Boolean}
     */
    isKpi (key) {
      return !this.notKpi.includes(key)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.overview-push-section {
  display: flex;
  padding-bottom: 3rem;
  padding-top: 1rem;

  @media only screen and (max-width: $--mobile-width) {
    flex-direction: column;
  }
  .col-left {
    width: 780px;
  }
  .col-right {
    width: 420px;
    .screen-choice {
      display: flex;
      max-width: 200px;
      margin: auto;
      justify-content: space-around;
      padding-bottom: 15px;
      .switch-button {
        cursor: pointer;
      }
    }
    .switch {
      text-align: center;
      margin-bottom: 1.2rem;
    }
    .preview {
      margin: auto;
    }
    .mobile-emulator-ios-locked {
      width: 354px;
      background-repeat: no-repeat;
      background-image: url("../../../assets/svg/push-notification/preview/ios-frame.png");
    }
    .mobile-emulator-ios-extended {
      width: 354px;
      background-repeat: no-repeat;
      background-image: url("../../../assets/svg/push-notification/preview/ios-frame.png");
    }
    .mobile-emulator-android-locked {
      width: 372px;
      background-repeat: no-repeat;
      background-image: url("../../../assets/svg/push-notification/preview/android-frame.svg");
    }
    .mobile-emulator-android-extended {
      width: 372px;
      background-repeat: no-repeat;
      background-image: url("../../../assets/svg/push-notification/preview/android-frame-extended.svg");
    }
    .mobile-emulator {
      height: 705 px;
      padding: 65px 0;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .screen {
        position: relative;
        width: 320px;
        min-height: 630px;
        max-height: 630px;
        height: 630px;
        .header-preview-android {
          padding:30px;
          .time {
            font-size: 61px;
            color: #fff;
            line-height: 1.1!important;
            text-align: center;
            font-weight: 400;
          }
          .date {
            text-transform: uppercase;
            font-size: 15px;
            color: #fff;
            text-align: center;
          }
        }
        .header-preview-ios {
          padding:30px;
          .time {
            margin-top: 10px;
            font-family: "San Francisco";
            font-weight: 300;
            font-size: 55px;
            color: #fff;
            line-height: 60px;
            text-align: center;
          }
          .date {
            font-size: 15px;
            color: #fff;
            text-align: center;
          }
          .lock {
            text-align: center;
          }
        }
        .body-preview-android {
          padding: 10px;
          margin-top: 20px;
          margin-left: 2px;
          margin-right: 3px;
          background-color: rgba(252,252,252,.7);
          margin-top: 46px;
          .body-preview-app-name {
            display: flex;
            color:#565555;
            font-size: 12px;
            .body-preview-app-name-item {
              padding-right: 5px;
            }
            .body-preview-app-name-now {
              font-weight:400;
              font-size:11px;
              color:#929292;
            }
          }
          .msg {
            font-size: 11px;
            overflow-wrap: break-word;
            margin-top: 3px;
            font-weight: 400px;
            white-space: pre-line;
            color: #444
          }
          .title {
            font-size: 13px;
            font-weight: 400;
            color: #000;
            overflow-wrap: break-word;
          }
          .footer {
            display: flex;
            text-transform: uppercase;
            font-weight: 700;
            color: #07636c;
            margin-top: 10px;
            font-size: 12px;
            .footer-item {
              padding-left: 20px
            }
          }
        }
        .extended {
          background-color: #f8f8f8!important;
        }
        .escape-ios {
          width: 20px;
          height: 20px;
          background-color: #f8f8f8ba;
          display: flex;
          border-radius: 100%;
          padding-left: 1px;
          padding-top: 1px;
        }
        .body-preview-ios {
          padding: 7px;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 10px;
          border-radius: 10px;
          background-color: rgba(255,255,255,.7);
          .body-preview-app-name {
            display: flex;
            color: #505161;
            font-size: 12px;
            .body-preview-app-name-extended {
              color: #A9A9AC;
              padding-left: 60%;
              i {
                font-size:5px; padding:1px
              }
            }
            .body-preview-app-name-item {
              padding-right: 5px;
            }
            .body-preview-app-name-now {
              font-weight:400;
              font-size:11px;
              color:#929292;
              text-align: right;
            }
          }
          .title {
            overflow-wrap: break-word;
            font-size: 12px;
            font-weight: 600;
            max-width: 150px;
            text-align: left;
            color: #000;
            padding: 2px;
          }
          .msg {
            overflow-wrap: break-word;
            font-weight: 400;
            max-width: 260px;
            text-align: left;
            color: #000;
            white-space: pre-line;
            padding: 2px
          }
        }
      }
    }
    @media only screen and (max-width: $--mobile-width) {
      width: 100%;
    }
  }
}
</style>
