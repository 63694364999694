import Vue from 'vue'
import axios from 'axios'

import APIException from './exceptions'


const CancelToken = axios.CancelToken
let searchTagsCancel

const SET_TAGS = 'SET_TAGS'
const SET_SEARCH_TAGS = 'SET_SEARCH_TAGS'
const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

const moduleTag = {
  namespaced: true,
  state: {
    tags: [],
    search: {
      query: '',
      tagFamilyId: null,
      tags: []
    }
  },
  mutations: {
    [SET_TAGS] (state, { tags, family }) {
      const tagsWithFamilyRef = tags.map(family => {
        family.tags.map(tag => {
          tag.family = { id: family.id, name: family.name }
          return tag
        })
        return family
      })
      
      if (tags.length || family === null) {
        state.tags = tagsWithFamilyRef
        return
      }
      // if setting tags only for a family
      const firsTags = tagsWithFamilyRef[0]
      state.tags = state.tags.map(
        family => family.id === firsTags.id ? {
          id: family.id,
          name: family.name,
          nb_tags: family.nb_tags,
          tags: firsTags.tags
        } : family
      )
    },
    [SET_SEARCH_QUERY] (state, { query, familyId }) {
      Vue.set(state.search, 'query', query)
      Vue.set(state.search, 'tagFamilyId', familyId)
    },
    [SET_SEARCH_TAGS] (state, tags) {
      Vue.set(state.search, 'tags', tags)
    }
  },
  actions: {
    async getTags ({ commit }) {
      try {
        const response = await Vue.axios.get('/api_v2/tags')
        if (response.status >= 300) { throw response }
        commit(SET_TAGS, { tags: response.data.objects })
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async setSearch ({ commit }, { query, familyId }) {
      familyId = query.length > 0 ? familyId : null
      commit(SET_SEARCH_QUERY, { query, familyId })
    },
    async searchTags ({ commit, state }) {
      if (searchTagsCancel) { searchTagsCancel() }
      // TODO(cky): If s is empty just reset search tags
      if (state.search.query.length === 0 || !state.search.tagFamilyId) {
        commit(SET_SEARCH_TAGS, [])
        return
      }
      try {
        const response = await Vue.axios.get('/api_v2/tags', {
          params: { family_id: state.search.tagFamilyId, s: state.search.query },
          cancelToken: new CancelToken(function executor(c) {
            searchTagsCancel = c
          })
        })
        if (response.status >= 300) { throw response }

        const tags = response.data.meta.total > 0 ? response.data.objects[0].tags : []
        commit(SET_SEARCH_TAGS, tags)
        return response
      } catch (err) {
        return new APIException(err)
      }
    }
  },
  getters: {
    tags: state => state.tags,
    tag: state => (id) => {
      for (const family of state.tags) {
        const tag = family.tags.find(t => t.id === id)
        if (tag) {
          tag.id = `${tag.family.id}:${tag.id}`
          return tag
        }
      }
      return null
    },
    search: state => state.search
  }
}

export default moduleTag
