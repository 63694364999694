
import {
  ViewHome,
  ViewEmailList,
  ViewEmailDetailOverview,
  ViewEmailDetailHosts,
  ViewEmailDetailClickMap,
  ViewEmailDetailConversions,
  ViewEmailTable,
  ViewSMSDetailOverview,
  ViewSMSDetailConversions,
  ViewSMSList,
  ViewSMSTable,
  ViewPushDetailOverview,
  ViewPushList,
  ViewPushTable,
  ViewPushDetailConversions,
  ViewLandingList,
  ViewLandingDetailClickMap,
  ViewLandingDetailOverview,
  ViewReportsList,
  ViewReportsDetail,
  ViewAdminReports,
  ViewQRList
} from '@/components/Views'
import {
  ContainerApp,
  ContainerEmailDetail,
  ContainerSMSDetail,
  ContainerPushDetail,
  ContainerLandingDetail,
  ContainerAdmin,
  ContainerQRDetail
} from '@/components/Containers'

const routes = [
  {
    path: '/',
    component: ContainerApp,
    children: [
      {
        name: 'home',
        path: '/',
        component: ViewHome,
        meta: {
          requiresCRM: true,
        },
      },
      // Email Campaigns
      {
        name: 'email-list',
        path: '/emails',
        component: ViewEmailList,
        meta: {
          title: 'TITLE.EMAIL_LIST',
          requiresCRM: true,
        }
      },
      {
        path: '/emails/:campaignId',
        component: ContainerEmailDetail,
        meta: {
          requiresCRM: true,
        },
        props: { isJourneyMessage: false },
        children: [
          {
            path: '',
            name: 'email-detail-overview',
            component: ViewEmailDetailOverview,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            },
          },
          {
            path: 'click-map',
            name: 'email-detail-click-map',
            component: ViewEmailDetailClickMap,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'conversions',
            name: 'email-detail-conversions',
            component: ViewEmailDetailConversions,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'hosts',
            name: 'email-detail-hosts',
            component: ViewEmailDetailHosts,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            }
          }
        ]
      },
      {
        path: '/email-messages/:campaignId',
        component: ContainerEmailDetail,
        meta: {
          requiresCRM: true,
        },
        props: { isJourneyMessage: true },
        children: [
          {
            path: '',
            name: 'messages-email-detail-overview',
            component: ViewEmailDetailOverview,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            },
          },
          {
            path: 'click-map',
            name: 'messages-email-detail-click-map',
            component: ViewEmailDetailClickMap,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'conversions',
            name: 'messages-email-detail-conversions',
            component: ViewEmailDetailConversions,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'hosts',
            name: 'messages-email-detail-hosts',
            component: ViewEmailDetailHosts,
            meta: {
              title: 'TITLE.EMAIL_LIST',
              hide_custom_bg: true
            }
          }
        ]
      },
      {
        name: 'email-detail-table',
        path: '/emails/:campaignId/table',
        component: ViewEmailTable,
        meta: {
          title: 'TITLE.EMAIL_LIST',
          hide_custom_bg: true,
          requiresCRM: true,
        }
      },
      // SMS Campaigns
      {
        name: 'sms-list',
        path: '/sms',
        component: ViewSMSList,
        meta: {
          title: 'TITLE.SMS_LIST',
          requiresCRM: true,
        }
      },
      {
        path: '/sms/:campaignId',
        component: ContainerSMSDetail,
        meta: {
          requiresCRM: true,
        },
        props: { isJourneyMessage: false },
        children: [
          {
            path: '',
            name: 'sms-detail-overview',
            component: ViewSMSDetailOverview,
            meta: {
              title: 'TITLE.SMS_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'conversions',
            name: 'sms-detail-conversions',
            component: ViewSMSDetailConversions,
            meta: {
              title: 'TITLE.SMS_LIST',
              hide_custom_bg: true
            }
          }
        ]
      },
      {
        path: '/sms-messages/:campaignId',
        component: ContainerSMSDetail,
        meta: {
          requiresCRM: true,
        },
        props: { isJourneyMessage: true },
        children: [
          {
            path: '',
            name: 'messages-sms-detail-overview',
            component: ViewSMSDetailOverview,
            meta: {
              title: 'TITLE.SMS_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'conversions',
            name: 'messages-sms-detail-conversions',
            component: ViewSMSDetailConversions,
            meta: {
              title: 'TITLE.SMS_LIST',
              hide_custom_bg: true
            }
          }
        ]
      },
      {
        name: 'sms-detail-table',
        path: '/sms/:campaignId/table',
        component: ViewSMSTable,
        meta: {
          title: 'TITLE.SMS_LIST',
          hide_custom_bg: true,
          requiresCRM: true,
        }
      },
      // PUSH Campaigns
      {
        name: 'push-list',
        path: '/push-notifications',
        component: ViewPushList,
        meta: {
          title: 'TITLE.PUSH_LIST',
          requiresCRM: true,
        }
      },
      {
        path: '/push-notifications/:campaignId',
        component: ContainerPushDetail,
        meta: {
          requiresCRM: true,
        },
        children: [
          {
            path: '',
            name: 'push-detail-overview',
            component: ViewPushDetailOverview,
            meta: {
              title: 'TITLE.PUSH_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'conversions',
            name: 'push-detail-conversions',
            component: ViewPushDetailConversions,
            meta: {
              title: 'TITLE.PUSH_LIST',
              hide_custom_bg: true
            }
          }
        ]
      },
      {
        name: 'push-detail-table',
        path: '/push-notifications/:campaignId/table',
        component: ViewPushTable,
        meta: {
          title: 'TITLE.PUSH_LIST',
          hide_custom_bg: true,
          requiresCRM: true,
        }
      },
      // Landing Page Contents
      {
        name: 'landing-list',
        path: '/landing-pages',
        component: ViewLandingList,
        meta: {
          title: 'TITLE.LANDING_LIST',
          requiresCRM: true,
        }
      },
      {
        path: '/landing-pages/:landingId',
        component: ContainerLandingDetail,
        meta: {
          requiresCRM: true,
        },
        children: [
          {
            path: '',
            name: 'landing-detail-overview',
            component: ViewLandingDetailOverview,
            meta: {
              title: 'TITLE.LANDING_LIST',
              hide_custom_bg: true
            }
          },
          {
            path: 'click-map',
            name: 'landing-detail-click-map',
            component: ViewLandingDetailClickMap,
            meta: {
              title: 'TITLE.LANDING_LIST',
              hide_custom_bg: true
            }
          }
        ]
      },
      // Journeys
      {
        name: 'journeys-list',
        path: '/journeys',
        beforeEnter(to, from, next) {
          if (to.path === '/journeys') {
            window.location.href = '/journeys'; // Trigger full page reload
          } else {
            next();
          }
        }
      },
      {
        name: 'journey-messages',
        path: '/journeys/:journeyId/messages',
        beforeEnter(to) {
            window.location.href = `/journeys/${to.params.journeyId}/messages`; // Trigger full page reload
        }
      },
      // Custom Reports
      {
        name: 'reports-list',
        path: '/reports',
        component: ViewReportsList,
        meta: {
          title: 'TITLE.REPORTS_LIST'
        }
      },
      {
        name: 'reports-detail',
        path: '/reports/:reportId',
        component: ViewReportsDetail
      },
      // QR Codes
      {
        name: 'qr-list',
        path: '/qr-codes',
        component: ViewQRList,
        meta: {
          title: 'TITLE.QR_LIST'
        }
      },
      {
        name: 'qr-detail',
        path: '/qr-codes/:qrId',
        component: ContainerQRDetail
      },
      // Admin
      {
        name: 'admin',
        path: '/admin',
        component: ContainerAdmin,
        children: [
          {
            path: 'reports',
            name: 'admin-reports',
            component: ViewAdminReports,
            meta: {
              title: 'TITLE.ADMIN_REPORTS',
              hide_custom_bg: true
            }
          }
        ]
      },
      // default route when none of the above matches
      {
        path: '*',
        redirect: '/'
      }
    ]
  }
]

export default routes
