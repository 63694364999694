import Vue from 'vue'

import APIException from './exceptions'


const CLEAR_SESSION = 'CLEAR_SESSION'
const SET_USER = 'SET_USER'
const SET_GROUPS = 'SET_GROUPS'

const moduleSession = {
  namespaced: true,
  state: {
    user: null,
    groups: []
  },
  mutations: {
    [SET_USER] (state, user) {
      Vue.set(state, 'user', user)
    },
    [CLEAR_SESSION] (state) {
      Vue.set(state, 'user', null)
    },
    [SET_GROUPS] (state, val) {
      state.groups = val
    },
  },
  actions: {
    async getProfile({ commit, dispatch }) {
      try {
        const response = await Vue.axios.get('/api_v2/sessions')
        if (response.status >= 300) { throw response }
        dispatch('clients/setClient', response.data.client, { root: true })
        dispatch('snapcall/setSnapcallId', response.data.client.configs.snapcall_id, { root: true })
        if (response.data.language) {
          // Set html lang=xx with right value from /sessions response.
          document.documentElement.lang = response.data.lang
        }
        commit(SET_USER, response.data)
        return response
      }
      catch (err) {
        return new APIException(err)
      }
    },
    async getGroups({ commit }) {
      try {
        const response = await Vue.axios.get('/api_v2/groups')
        if (response.status >= 300) { throw response }

        commit(SET_GROUPS, response.data.objects)
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    clearSession({ commit }) {
      commit(CLEAR_SESSION)
    },
    logout({ commit }) {
      commit(CLEAR_SESSION)
      window.location = '/logout'
    }
  },
  getters: {
    user: state => state.user,
    groups: state => state.groups,
    client: state => state.user ? state.user.client : null,
    reportsConfig: state => state.user ? state.user.reports : {},
    hasModuleQR: state => state.user ? state.user.client.configs.module_qr_code : false,
    hasModuleJourneys: state => state.user ? state.user.client.configs.journey_activated : false,
    hasModuleDataExport: state => state.user ? state.user.client.configs.dashboardly_module_export_data : false,
    hasPermission: (state) => (perm) => {
      if (!state.user) {
        return false
      }
      let permSplitted = perm.split(':')
      if (permSplitted.length < 3) {
        return false
      }
      switch (permSplitted[1]) {
        case 'user':
          return state.user.permissions.includes(perm)
        case 'role': {
          const groupsWithPermissions = state.user.groups.filter(group => group.permissions.includes(perm))
          return groupsWithPermissions.length > 0
        }
        default:
          return false
      }
    },
    hasCRMDataBase: (state, getters) => {
      return !!getters.client && !!getters.client.configs.SENDING_CRM_DB_NAME
    }
  }
}

export default moduleSession
